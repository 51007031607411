<template>
    <v-card
      class="mx-auto"
      max-width="344"
    >
      <v-card-text>
        <div>Месторождение</div>
        <p class="text-h4 text--primary">
          Айракты
        </p>
        <p>Статус</p>
        <div class="text--primary">
          Фаза:<br>
          Стадия:
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="deep-purple accent-4"
        >
          Подробнее
        </v-btn>
      </v-card-actions>
    </v-card>
  </template>
  <script>
  export default {
  }
</script>